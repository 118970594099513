.scale-in-top {
	-webkit-animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes scale-in-top {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          opacity: 1;
        }
        50% {
                -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                -webkit-transform-origin: 50% 0%;
                        transform-origin: 50% 0%;
                opacity: 1;
              }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          opacity: 1;
        }
      }
      @keyframes scale-in-top {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          opacity: 1;
        }
        50% {
                -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                -webkit-transform-origin: 50% 0%;
                        transform-origin: 50% 0%;
                opacity: 1;
              }
        
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          opacity: 1;
        }
      }
      .scale-in-tr {
	-webkit-animation: scale-in-tr 0.15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-tr 0.15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-in-tr {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
      }
      @keyframes scale-in-tr {
        0% {
          -webkit-transform: scale(0);
                  transform: scale(0);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
          -webkit-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
          opacity: 1;
        }
      }
      
      .scale-in-br-automation {
        -webkit-animation: scale-in-br .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .4s both;
        animation: scale-in-br .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .4s both;
    }

 @-webkit-keyframes scale-in-br {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-br {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
        opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  


  .scale-in-bl-automation {
	-webkit-animation: scale-in-bl-automation 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .2s both;
	        animation: scale-in-bl-automation 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .2s both;
}


 @-webkit-keyframes scale-in-bl-automation {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        opacity: 1;
      }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-bl-automation {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        opacity: 1;
      }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
  }

  .scale-in-bl-automation1 {
	-webkit-animation: scale-in-bl-automation1 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
	        animation: scale-in-bl-automation1 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
}


 @-webkit-keyframes scale-in-bl-automation1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        opacity: 1;
      }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-bl-automation1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        opacity: 1;
      }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
  }

  .scale-in-bl-automation2 {
	-webkit-animation: scale-in-bl-automation2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
	        animation: scale-in-bl-automation2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
}


 @-webkit-keyframes scale-in-bl-automation2 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        opacity: 1;
      }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
  }
  @keyframes scale-in-bl-automation2 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transform-origin: 0% 100%;
                transform-origin: 0% 100%;
        opacity: 1;
      }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
      opacity: 1;
    }
  }
  

  .slide-in-elliptic-top-fwd {
	-webkit-animation: slide-in-elliptic-top-fwd .4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-fwd .4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-in-elliptic-top-fwd {
        0% {
          -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
                  transform: translateY(-600px) rotateX(-30deg) scale(0);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% 900px;
                  transform-origin: 50% 900px;
          opacity: 1;
        }
      }
      @keyframes slide-in-elliptic-top-fwd {
        0% {
          -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
                  transform: translateY(-600px) rotateX(-30deg) scale(0);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% 900px;
                  transform-origin: 50% 900px;
          opacity: 1;
        }
      }
      
      .slide-in-elliptic-top-bck {
	-webkit-animation: slide-in-elliptic-top-bck .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-bck .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-in-elliptic-top-bck {
        0% {
          -webkit-transform: translateY(-100px) rotateX(30deg) scale(1.5);
                  transform: translateY(-100px) rotateX(30deg) scale(1.5);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% -500px;
                  transform-origin: 50% -500px;
          opacity: 1;
        }
      }
      @keyframes slide-in-elliptic-top-bck {
        0% {
          -webkit-transform: translateY(-100px) rotateX(30deg) scale(1.5);
                  transform: translateY(-100px) rotateX(30deg) scale(1.5);
          -webkit-transform-origin: 50% 100%;
                  transform-origin: 50% 100%;
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) rotateX(0) scale(1);
                  transform: translateY(0) rotateX(0) scale(1);
          -webkit-transform-origin: 50% -500px;
                  transform-origin: 50% -500px;
          opacity: 1;
        }
      }
      
      .scale-in-hor-left {
	-webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes scale-in-hor-left {
        0% {
          -webkit-transform: scaleX(0);
                  transform: scaleX(0);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(1);
                  transform: scaleX(1);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
      }
      @keyframes scale-in-hor-left {
        0% {
          -webkit-transform: scaleX(0);
                  transform: scaleX(0);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(1);
                  transform: scaleX(1);
          -webkit-transform-origin: 0% 0%;
                  transform-origin: 0% 0%;
          opacity: 1;
        }
      }
      
      .scale-in-hor-right {
	-webkit-animation: scale-in-hor-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-in-hor-right {
        0% {
          -webkit-transform: scaleX(0);
                  transform: scaleX(0);
          -webkit-transform-origin: 100% 100%;
                  transform-origin: 100% 100%;
                  background: #16A085;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(1);
                  transform: scaleX(1);
          -webkit-transform-origin: 100% 100%;
                  transform-origin: 100% 100%;
                  background: #9CA3AF;
          opacity: 1;
        }
      }
      @keyframes scale-in-hor-right {
        0% {
          -webkit-transform: scaleX(0);
                  transform: scaleX(0);
          -webkit-transform-origin: 100% 100%;
          background: #16A085;

                  transform-origin: 100% 100%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(1);
                  transform: scaleX(1);
          -webkit-transform-origin: 100% 100%;
                  transform-origin: 100% 100%;
                  background: #9CA3AF;

          opacity: 1;
        }
      }

      .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
    
@-webkit-keyframes slide-in-bottom {
        0% {
          -webkit-transform: translateY(100px);
                  transform: translateY(100px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-bottom {
        0% {
          -webkit-transform: translateY(100px);
                  transform: translateY(100px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      

      .flip-in-ver-right {
	-webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      @keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      

      .flip-in-ver-left {
	-webkit-animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes flip-in-ver-left {
        0% {
          -webkit-transform: rotateY(80deg);
                  transform: rotateY(80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      @keyframes flip-in-ver-left {
        0% {
          -webkit-transform: rotateY(80deg);
                  transform: rotateY(80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      

      .slide-in-bottom-email {
	-webkit-animation: slide-in-bottom-email 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom-email 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
    
@-webkit-keyframes slide-in-bottom-email {
        0% {
          -webkit-transform: translateY(30px);
                  transform: translateY(30px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-bottom-email {
        0% {
          -webkit-transform: translateY(30px);
                  transform: translateY(30px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      

      .color-change {
        animation: color-change 16s infinite;
}

@keyframes color-change {
        0% { color: green; }
        25% { color: red; }
        50% { color: yellow; }
        75% { color: #4c77e0; }
        100% { color: green; }
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-in-right {
        0% {
          -webkit-transform: translateX(70px);
                  transform: translateX(70px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-right {
        0% {
          -webkit-transform: translateX(70px);
                  transform: translateX(70px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }
      
     
      
      .slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes slide-out-right {
        0% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateX(100px);
                  transform: translateX(100px);
          opacity: 0;
        }
      }
      @keyframes slide-out-right {
        0% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateX(100px);
                  transform: translateX(100px);
          opacity: 0;
        }
      }
      
      .swirl-in-fwd {
	-webkit-animation: swirl-in-fwd 0.6s ease-out both;
	        animation: swirl-in-fwd 0.6s ease-out both;
}


 @-webkit-keyframes swirl-in-fwd {
        0% {
          -webkit-transform: rotate(-540deg) scale(0);
                  transform: rotate(-540deg) scale(0);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotate(0) scale(1);
                  transform: rotate(0) scale(1);
          opacity: 1;
        }
      }
      @keyframes swirl-in-fwd {
        0% {
          -webkit-transform: rotate(-540deg) scale(0);
                  transform: rotate(-540deg) scale(0);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotate(0) scale(1);
                  transform: rotate(0) scale(1);
          opacity: 1;
        }
      }
      
      .flip-in-ver-right {
	-webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      @keyframes flip-in-ver-right {
        0% {
          -webkit-transform: rotateY(-80deg);
                  transform: rotateY(-80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      .flip-in-ver-left {
	-webkit-animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes flip-in-ver-left {
        0% {
          -webkit-transform: rotateY(80deg);
                  transform: rotateY(80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      @keyframes flip-in-ver-left {
        0% {
          -webkit-transform: rotateY(80deg);
                  transform: rotateY(80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateY(0);
                  transform: rotateY(0);
          opacity: 1;
        }
      }
      .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-in-bottom {
        0% {
          -webkit-transform: translateY(1000px);
                  transform: translateY(1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-bottom {
        0% {
          -webkit-transform: translateY(1000px);
                  transform: translateY(1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      
      .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-bottom {
        0% {
          -webkit-transform: translateY(30px);
                  transform: translateY(30px);
          opacity: 0.5;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-bottom {
        0% {
          -webkit-transform: translateY(30px);
                  transform: translateY(30px);
          opacity: 0.5;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      