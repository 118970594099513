@font-face {
    font-family: GraphikRegular ;
    src: url(./Graphik-Font-Family/GraphikRegular.otf);
  }
  @font-face {
    font-family: GraphikMedium ;
    src: url(./Graphik-Font-Family/GraphikMedium.otf);
  }
  @font-face {
    font-family: GraphikSemibold ;
    src: url(./Graphik-Font-Family/GraphikSemibold.otf);
  }
  @font-face {
    font-family: GraphikBold;
    src: url(./Graphik-Font-Family/GraphikBold.otf);
  }
  
  .medium{
    font-family: GraphikMedium ;
  }
  .semibold{
    font-family: GraphikSemibold ;
  }
  .bold{
    font-family: GraphikBold ;
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: GraphikRegular, 'Poppins';
    border-color:#e0e6ee;
  }


 
  .sharedLayout{  
    width: 100%;
    height: calc(100vh - 3.52rem);

  }

  .nav {
    position: relative;
    color: rgb(158, 169, 184);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 8px;
    height: 2.5rem;
    padding: 1rem;
}
.nav-active {
  position: relative;
  color: #fafbfd;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  height: 2.5rem;
  padding: 1rem;
}
.nav-active:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -8px;
  left: 0px;
  
  border-bottom: 4px solid #466eff;
}

   .nav:hover:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -8px;
    left: 0px;
    
    border-bottom: 4px solid #466eff;
}
/* .nav:focus:before{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -8px;
  left: 0px;
  
  border-bottom: 4px solid #466eff;
} */

.box-shadow{
  box-shadow: rgba(18, 21, 26, 0.08) 0px 0px 0px 0.5px, rgba(18, 21, 26, 0.04) 0px 2px 2px, rgba(18, 21, 26, 0.08) 0px 4px 8px -2px;
}

