.custom-layout .react-colorful {
    /* padding-right: 16px; */
    /* padding-left: 16px; */
    /* border-radius: 12px; */
    /* padding-bottom: 10px; */
    width: 217px;
    /* width: 70%; */
    height: 150px ;
    background: #fff;
    border-radius: 3px;
    /* overflow: hidden; */
    box-shadow: rgba(0, 0, 0, 0.2) 3px 5px 10px;
  }
  
  .custom-layout .react-colorful__saturation {
    /* margin: 0px 0 15px 0; */
    /* height: 12px; */
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    border-bottom: none;
  }
  
  .custom-layout .react-colorful__hue {
    order: 1;
  }
  
  .custom-layout .react-colorful__hue,
  .custom-layout .react-colorful__alpha {
    height: 12px;
    margin: 12px;
    border-radius: 0px;
  }
  
  .custom-layout .react-colorful__hue-pointer,
  .custom-layout .react-colorful__alpha-pointer {
    width: 12px;
    height: 12px;
  }
  .custom-pointers .react-colorful__saturation-pointer {
    width: 14px;
    height: 14px;
    border-radius: 10px;
  }
  