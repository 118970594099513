@tailwind base;  
@layer base { 
   
    *{
      border-color:#E0E6EE;
    }
}

@tailwind components;
@tailwind utilities;

