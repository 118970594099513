
.speech-wrapper{
    padding: 30px 25px;
    .bubble{
      max-width: 240px;
      height: auto;
      display: block;
      background: #f5f5f5;
      padding-bottom: 12px;
      border-radius: 12px;
      /* box-shadow: 2px 8px 5px #000; */
      position: relative;
      margin: 0 0 25px;
      &.alt{
        margin: 0 0 0 60px;
      }
      .txt{
        padding: 8px 14px;
        font-family: 'GraphikRegular';
        .name{
          font-weight: 600;
          font-size: 12px;
          margin: 0 0 4px;
          color: #3498db;
          span{
            font-weight: normal;
            color: #b3b3b3;
          }
          &.alt{
            color: #2ecc71;
          }
        }
        .message{
          font-size: 12px;
          margin: 0;
          color: #1A202c;
        }
        .timestamp{
          font-size: 9px;
          position: absolute;
          bottom: 8px;
          right: 10px;
          text-transform: uppercase; color: #999
        }
      }
      .bubble-arrow {
        position: absolute;
        width: 0;
        bottom:38px;
        left: -16px;
        height: 0;
        &.alt{
          right: -2px;      
          bottom: 40px;
          left: auto;
        }
      }
      .bubble-arrow:after {
        content: "";
        position: absolute;
        border: 0 solid transparent;
        border-top: 9px solid #f5f5f5;
        border-radius: 0 20px 0;
        width: 15px;
        height: 30px;
        transform: rotate(145deg);
      }
      .bubble-arrow.alt:after {
        transform: rotate(45deg) scaleY(-1);
      }
    }
  }


  
.speech-wrapper-template{
  padding: 30px 25px;
  .bubble-template{
    max-width: 240px;
    height: auto;
    display: block;
    /* background: #f5f5f5; */
    background: white;

    padding-bottom: 7px;
    border-radius: 12px;
    /* box-shadow: 2px 8px 5px #000; */
    position: relative;
    margin: 0 0 25px;
    &.alt{
      margin: 0 0 0 60px;
    }
    .txt-template{
      padding: 8px 14px;
      font-family: 'GraphikRegular';
      .name-template{
        /* font-weight: 600; */
        font-size: 13px;
        font-family: GraphikMedium;
        margin: 0 0 8px;
        color: #292e36;
        span{
          font-weight: normal;
          color: #b3b3b3;
        }
        &.alt{
          color: #2ecc71;
        }
      }
      .message-template{
        font-size: 12px;
        margin-bottom: 8px;
        color: #555c67;
      }
      .timestamp-template{
        font-size: 9px;
        padding: 5px 0px 0px 0px;
        position: absolute;
        bottom: -8px;
        right: 10px;
        text-transform: uppercase; color: #999
      }
    }
    .bubble-arrow-template {
      position: absolute;
      width: 0;
      bottom:38px;
      left: -16px;
      height: 0;
      &.alt{
        right: -2px;      
        bottom: 40px;
        left: auto;
      }
    }
    .bubble-arrow-template:after {
      content: "";
      position: absolute;
      border: 0 solid transparent;
      /* border-top: 9px solid #f5f5f5; */
      border-top: 9px solid white;

      border-radius: 0 20px 0;
      width: 15px;
      height: 30px;
      transform: rotate(145deg);
    }
    .bubble-arrow-template.alt:after {
      transform: rotate(45deg) scaleY(-1);
    }
  }
}