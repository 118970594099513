.bubble-webchat{
    display: flex;
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    padding: 26px 30px;
    line-height: 1.5em;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 24px;
    word-break: break-word;
    max-width: 282px;
    align-self: flex-end;
  }
   
  .bubble-webchat::before {
    content: "";
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: -6px;
    transform: rotate(45deg);
    right: 17px;
  }